import React from 'react';

export default function InvestmentsSectionFeatures1() {
    return (
        <React.Fragment>
            <>
                <section className="relative pt-20 pb-20 lg:pb-32 overflow-hidden">
  <img className="absolute top-0 right-0" src="saturn-assets/images/features/star-element-right.png" alt />
  <div className="relative container px-4 mx-auto">
    <div className="max-w-md lg:max-w-7xl mx-auto">
      <div className="flex flex-wrap -mx-4 mb-18 items-center">
        <div className="w-full lg:w-2/3 xl:w-1/2 px-4 mb-12 lg:mb-0">
          <div className="max-w-lg">
            <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full">FEATURES</span>
            <h1 className="font-heading text-5xl xs:text-6xl md:text-7xl font-bold text-gray-900">
              <span>Investments</span>
              <span className="font-serif italic">Studies</span>
            </h1>
          </div>
        </div>
        <div className="w-full lg:w-1/3 xl:w-1/2 px-4">
          <div className="max-w-lg lg:ml-auto">
            <p className="text-lg text-gray-500">AGGREGATE FROM REPUBLIC</p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-1/3 px-4 mb-12 lg:mb-0">
          <div>
            <img className="block mb-6 w-full h-100" src="saturn-assets/images/features/image-article1.png" alt />
            <div>
              <h3 className="text-3xl font-semibold mb-5">The heart of what makes we valuable</h3>
              <a className="inline-flex items-center justify-center w-full lg:w-auto px-5 py-3 text-sm font-semibold text-orange-900 rounded-full border border-gray-100 hover:border-orange-900 transition duration-200" href="#">
                <span className="mr-3">Learn More</span>
                <svg width={13} height={13} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.9199 0.62C12.8185 0.375651 12.6243 0.181475 12.3799 0.0799999C12.2597 0.028759 12.1306 0.00157999 11.9999 0H1.99994C1.73472 0 1.48037 0.105357 1.29283 0.292893C1.1053 0.48043 0.999939 0.734784 0.999939 1C0.999939 1.26522 1.1053 1.51957 1.29283 1.70711C1.48037 1.89464 1.73472 2 1.99994 2H9.58994L1.28994 10.29C1.19621 10.383 1.12182 10.4936 1.07105 10.6154C1.02028 10.7373 0.994141 10.868 0.994141 11C0.994141 11.132 1.02028 11.2627 1.07105 11.3846C1.12182 11.5064 1.19621 11.617 1.28994 11.71C1.3829 11.8037 1.4935 11.8781 1.61536 11.9289C1.73722 11.9797 1.86793 12.0058 1.99994 12.0058C2.13195 12.0058 2.26266 11.9797 2.38452 11.9289C2.50638 11.8781 2.61698 11.8037 2.70994 11.71L10.9999 3.41V11C10.9999 11.2652 11.1053 11.5196 11.2928 11.7071C11.4804 11.8946 11.7347 12 11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V1C12.9984 0.869323 12.9712 0.740222 12.9199 0.62Z" fill="#FF460C" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3 px-4 mb-12 lg:mb-0">
          <div>
            <img className="block mb-6 w-full h-100" src="saturn-assets/images/features/image-article2.png" alt />
            <div>
              <h3 className="text-3xl font-semibold mb-5">The heart of what makes we valuable</h3>
              <a className="inline-flex items-center justify-center w-full lg:w-auto px-5 py-3 text-sm font-semibold text-orange-900 rounded-full border border-gray-100 hover:border-orange-900 transition duration-200" href="#">
                <span className="mr-3">Learn More</span>
                <svg width={13} height={13} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.9199 0.62C12.8185 0.375651 12.6243 0.181475 12.3799 0.0799999C12.2597 0.028759 12.1306 0.00157999 11.9999 0H1.99994C1.73472 0 1.48037 0.105357 1.29283 0.292893C1.1053 0.48043 0.999939 0.734784 0.999939 1C0.999939 1.26522 1.1053 1.51957 1.29283 1.70711C1.48037 1.89464 1.73472 2 1.99994 2H9.58994L1.28994 10.29C1.19621 10.383 1.12182 10.4936 1.07105 10.6154C1.02028 10.7373 0.994141 10.868 0.994141 11C0.994141 11.132 1.02028 11.2627 1.07105 11.3846C1.12182 11.5064 1.19621 11.617 1.28994 11.71C1.3829 11.8037 1.4935 11.8781 1.61536 11.9289C1.73722 11.9797 1.86793 12.0058 1.99994 12.0058C2.13195 12.0058 2.26266 11.9797 2.38452 11.9289C2.50638 11.8781 2.61698 11.8037 2.70994 11.71L10.9999 3.41V11C10.9999 11.2652 11.1053 11.5196 11.2928 11.7071C11.4804 11.8946 11.7347 12 11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V1C12.9984 0.869323 12.9712 0.740222 12.9199 0.62Z" fill="#FF460C" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3 px-4">
          <div>
            <img className="block mb-6 w-full h-100" src="saturn-assets/images/features/image-article3.png" alt />
            <div>
              <h3 className="text-3xl font-semibold mb-5">The heart of what makes we valuable</h3>
              <a className="inline-flex items-center justify-center w-full lg:w-auto px-5 py-3 text-sm font-semibold text-orange-900 rounded-full border border-gray-100 hover:border-orange-900 transition duration-200" href="#">
                <span className="mr-3">Learn More</span>
                <svg width={13} height={13} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.9199 0.62C12.8185 0.375651 12.6243 0.181475 12.3799 0.0799999C12.2597 0.028759 12.1306 0.00157999 11.9999 0H1.99994C1.73472 0 1.48037 0.105357 1.29283 0.292893C1.1053 0.48043 0.999939 0.734784 0.999939 1C0.999939 1.26522 1.1053 1.51957 1.29283 1.70711C1.48037 1.89464 1.73472 2 1.99994 2H9.58994L1.28994 10.29C1.19621 10.383 1.12182 10.4936 1.07105 10.6154C1.02028 10.7373 0.994141 10.868 0.994141 11C0.994141 11.132 1.02028 11.2627 1.07105 11.3846C1.12182 11.5064 1.19621 11.617 1.28994 11.71C1.3829 11.8037 1.4935 11.8781 1.61536 11.9289C1.73722 11.9797 1.86793 12.0058 1.99994 12.0058C2.13195 12.0058 2.26266 11.9797 2.38452 11.9289C2.50638 11.8781 2.61698 11.8037 2.70994 11.71L10.9999 3.41V11C10.9999 11.2652 11.1053 11.5196 11.2928 11.7071C11.4804 11.8946 11.7347 12 11.9999 12C12.2652 12 12.5195 11.8946 12.707 11.7071C12.8946 11.5196 12.9999 11.2652 12.9999 11V1C12.9984 0.869323 12.9712 0.740222 12.9199 0.62Z" fill="#FF460C" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

