import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SoftwareDevelopmentInFiveYearsSectionNavigations3 from '../components/navigations/SoftwareDevelopmentInFiveYearsSectionNavigations3';
import SoftwareDevelopmentInFiveYearsSectionContent2 from '../components/content/SoftwareDevelopmentInFiveYearsSectionContent2';
import SoftwareDevelopmentInFiveYearsSectionFooters4 from '../components/footers/SoftwareDevelopmentInFiveYearsSectionFooters4';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function SoftwareDevelopmentInFiveYears() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <SoftwareDevelopmentInFiveYearsSectionNavigations3 />
      <SoftwareDevelopmentInFiveYearsSectionContent2 />
      <SoftwareDevelopmentInFiveYearsSectionFooters4 />
    </React.Fragment>
  );
}

