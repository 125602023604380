import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionNavigations2 from '../components/navigations/IndexSectionNavigations2';
import IndexSectionHeaders1 from '../components/headers/IndexSectionHeaders1';
import IndexSectionBlog3 from '../components/blog/IndexSectionBlog3';
import IndexSectionFooters4 from '../components/footers/IndexSectionFooters4';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionNavigations2 />
      <IndexSectionHeaders1 />
      <IndexSectionBlog3 />
      <IndexSectionFooters4 />
    </React.Fragment>
  );
}

