import React from 'react';

export default function IndexSectionFooters4() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-20 overflow-hidden">  <img className="absolute top-0 left-0" src="saturn-assets/images/footers/blue-light-left.png" alt />  <img className="absolute bottom-0 left-1/2" src="saturn-assets/images/footers/orange-light-bottom.png" alt />  <div className="relative container px-4 mx-auto">    <div className="max-w-7xl mx-auto">      <div className="pb-8 mb-15 border-b border-gray-100 text-center"><a className="inline-block" href="#" /></div>      <div className="flex flex-wrap -mb-5 -mx-6 items-center justify-center">        <div className="px-6 mb-5"><a className="inline-block text-gray-900 hover:text-gray-600" href="#">Careers</a></div>        <div className="px-6 mb-5"><a className="inline-block text-gray-900 hover:text-gray-600" href="#">Help</a></div>        <div className="px-6 mb-5" />    </div>      <div className="mt-15 text-center">        <span className="text-gray-500">© 2025 Bluer Inc. All rights reserved.</span>    </div>  </div></div></section>


            </>
        </React.Fragment>
    );
}

