import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import InvestmentsPage from './pages/Investments.js';
import ResearchPage from './pages/Research.js';
import SoftwareDevelopmentInFiveYearsPage from './pages/Software-development-in-five-years.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
         
      <Route path="/investments" element={<InvestmentsPage />} />
         
      <Route path="/research" element={<ResearchPage />} />
         
      <Route path="/softwaredevelopmentinfiveyears" element={<SoftwareDevelopmentInFiveYearsPage />} />
    </Routes>
  );
}

export default App;
